import { SUBJECTS } from '@/data/constants';
/* eslint-disable key-spacing, quote-props */

export default {
  // route name
  'index':                                            'school_synergy_ru',
  'online-school':                                    'new_online_school_school',
  'ege':                                              'new_online_school_ege',
  'oge':                                              'new_online_school_oge',
  'externat':                                         'new_online_school_externat',
  'externat-ceny':                                    'schus_externat_ceny',
  'externat-5-7klass':                                'schus_externat_5-7_klass',
  'externat-8-9klass':                                'schus_externat_8-9_klass',
  'externat-10-11klass':                              'schus_externat_10-11_klass',
  'repetitor':                                        'new_online_school_repetitor',
  'halloween':                                        'new_online_school_halloween',
  'soft-skills':                                      'new_online_school_soft_skills',
  'itogovoe-sochinenie':                              'new_online_school_itogovoe_sochinenie',
  'itogovoe-sobesedovanie-2024':                      'new_online_school_itogovoe_sobesedovanie_2024',
  'happy-new-year-2024':                              'new_online_school_happy_new_year_2024',
  'marathon-ege-2024':                                'new_online_school_marathon_ege_2024',
  'marathon-ege-matematika-2024':                     'new_online_school_marathon_ege_matematika_2024',
  'marathon-ege-russkij-2024':                        'new_online_school_marathon_ege_russkij_2024',
  'events-open-day-21-03-2024':                       'new_online_school_events_dod_21_03_2024',
  'events-open-day-08-04-2024':                       'new_online_school_events_dod_08_04_2024',
  'events-open-day-26-04-2024':                       'new_online_school_events_dod_26_04_2024',
  'intensiv-oge':                                     'new_online_school_intensiv_oge',
  'intensiv-ege':                                     'new_online_school_intensiv_ege',
  'promo-9-may':                                      'new_online_school_promo_9_may',
  'online-school-ceny':                               'new_online_school_school_ceny',
  'online-school-ceny-poddomen':                      'new_online_school_ceny_poddomen',
  'proforientacia':                                   'new_online_school_proforientacia',
  'proforientacia-9-11-klass':                        'new_online_school_proforientacia',
  'proforientacia-9-11-klass-kd':                     'sckd',
  'proforientacia-test':                              'new_online_school_proforientacia',
  'proforientacia-drb':                               'new_online_school_proforientacia_drb',
  'school-university':                                'new_online_school_school_university',
  'thanks-shus':                                      'new_online_school_thanks_shus',
  'ege-ceny':                                         'new_online_school_ege_ceny',
  'oge-ceny':                                         'new_online_school_oge_ceny',
  'otzyvy':                                           'new_online_school_otzyvy',
  'catalog-courses':                                  'schus_catalog_courses',
  'catalog-abiturientam':                             'schus_catalog_abiturientam',
  'catalog-repetitor':                                'new_online_school_catalog_repetitor',
  'catalog-repetitor-first':                          'new_online_school_catalog_repetitor',
  'catalog-repetitor-second':                         'new_online_school_catalog_repetitor',
  'catalog-repetitor-third':                          'new_online_school_catalog_repetitor',
  'catalog-programmirovanie-dlya-detej':              'new_online_school_catalog_programmirovanie',
  'catalog-programmirovanie-dlya-detej-first':        'new_online_school_catalog_programmirovanie',
  'catalog-programmirovanie-dlya-detej-first-second': 'new_online_school_catalog_programmirovanie',
  'externat-dlya-vzroslyh':                           'new_online_school_externat_dlya_vzroslyh',

  'online-school-partners':                           'new_online_school_school_partners',
  'online-school-zaochnoe-obuchenie':                 'new_online_school_school_zaochnoe',
  'online-school-attestacii-i-prikreplenie':          'new_online_school_school_attestacii',
  'online-school-vechernee-obuchenie':                'new_online_school_vechernee_obuchenie',
  'online-school-semeinoe-obuchenie':                 'new_online_school_semeinoe_obuchenie',
  'online-school-5-klass':                            'new_online_school_school_5',
  'online-school-6-klass':                            'new_online_school_school_6',
  'online-school-7-klass':                            'new_online_school_school_7',
  'online-school-8-klass':                            'new_online_school_school_8',
  'online-school-9-klass':                            'new_online_school_school_9',
  'online-school-10-klass':                           'new_online_school_school_10',
  'online-school-11-klass':                           'new_online_school_school_11',
  'online-school-gumanitarnyj':                       'new_online_school_school_gumanitarnyj',
  'online-school-fiziko-matematicheskij':             'new_online_school_school_fiziko_matematicheskij',
  'online-school-lingvisticheskij':                   'new_online_school_school_lingvisticheskij',
  'online-school-socialno-ekonomicheskij':            'new_online_school_school_socialno_ekonomicheskij',
  'online-school-ceny-2':                             'schus_school_school_ceny_2',
  'online-school-ceny-dubai':                         'schus_school_ceny_dubai ',

  'catalog-courses-mentalnaya-arifmetika':            'schus_catalog_courses_mentalnaya_arifmetika',
  'catalog-courses-uglublennaya-geometriya':          'schus_catalog_courses_uglublennaya_geometriya',
  'catalog-courses-construct-3':                      'schus_catalog_courses_construct_3',
  'catalog-courses-roblox':                           'schus_catalog_courses_roblox',
  'catalog-courses-web-programmirovanie':             'schus_catalog_courses_web_programmirovanie',
  'catalog-courses-python':                           'schus_catalog_courses_python',
  'catalog-courses-anglijskij-dlya-detej':            'schus_catalog_courses_anglijskij_dlya_detej',
  'catalog-courses-uglublennyj-anglijskij':           'schus_catalog_courses_uglublennyj_anglijskij',
  'catalog-courses-literaturnyj-kruzhok':             'schus_catalog_courses_literaturnyj_kruzhok',
  'catalog-courses-kurs-razvitie-rechi':              'schus_catalog_courses_kurs_razvitie_rechi',
  'catalog-courses-osnovy-risunka':                   'schus_catalog_courses_osnovy_risunka',
  'catalog-courses-dizajn-prezentacij':               'schus_catalog_courses_dizajn_prezentacij',
  'catalog-courses-fin-gramotnost-base':              'schus_catalog_courses_fin_gramotnost_base',
  'catalog-courses-fin-gramotnost-pro':               'schus_catalog_courses_fin_gramotnost_pro',
  'catalog-courses-ekonomika-dlya-detej':             'schus_catalog_courses_ekonomika_dlya_detej',

  'sale':                                             'new_online_school_black_friday',
  'material_category':                                'new_school_blog',
  'articles':                                         'new_school_blog',

  'ege-moscow':                                       'new_online_school_ege_kurs_moscow',
  'ege-chelyabinsk':                                  'new_online_school_ege_kurs_chelyabinsk',
  'ege-kazan':                                        'new_online_school_ege_kurs_kazan',
  'ege-krasnoyarsk':                                  'new_online_school_ege_kurs_krasnoyarsk',
  'ege-nizhny-novgorod':                              'new_online_school_ege_kurs_nizhny-novgorod',
  'ege-novosibirsk':                                  'new_online_school_ege_kurs_novosibirsk',
  'ege-saint-petersburg':                             'new_online_school_ege_kurs_saint-petersburg',
  'ege-samara':                                       'new_online_school_ege_kurs_samara',
  'ege-ufa':                                          'new_online_school_ege_kurs_ufa',
  'ege-yekaterinburg':                                'new_online_school_ege_kurs_yekaterinburg',

  // route path
  [`/oge/${SUBJECTS.matematika}`]:        'new_online_school_oge_matematika',
  [`/oge/${SUBJECTS.russkijYazyk}`]:      'new_online_school_oge_russkij-yazyk',
  [`/oge/${SUBJECTS.anglijskijYazyk}`]:   'new_online_school_oge_anglijskij-yazyk',
  [`/oge/${SUBJECTS.informatika}`]:       'new_online_school_oge_informatika',
  [`/oge/${SUBJECTS.obshchestvoznanie}`]: 'new_online_school_oge_obshchestvoznanie',
  [`/oge/${SUBJECTS.istoriya}`]:          'new_online_school_oge_istoriya',
  [`/oge/${SUBJECTS.fizika}`]:            'new_online_school_oge_fizika',
  [`/oge/${SUBJECTS.himiya}`]:            'new_online_school_oge_himiya',
  [`/oge/${SUBJECTS.biologiya}`]:         'new_online_school_oge_biologiya',

  [`/ege/${SUBJECTS.matematika}`]:         'new_online_school_ege_matematika',
  [`/ege/${SUBJECTS.matematikaProfil}`]:   'new_online_school_ege_matematika-profil',
  [`/ege/${SUBJECTS.russkijYazyk}`]:       'new_online_school_ege_russkij-yazyk',
  [`/ege/${SUBJECTS.anglijskijYazyk}`]:    'new_online_school_ege_anglijskij-yazyk',
  [`/ege/${SUBJECTS.informatika}`]:        'new_online_school_ege_informatika',
  [`/ege/${SUBJECTS.istoriya}`]:           'new_online_school_ege_istoriya',
  [`/ege/${SUBJECTS.obshchestvoznanie}`]:  'new_online_school_ege_obshchestvoznanie',
  [`/ege/${SUBJECTS.biologiya}`]:          'new_online_school_ege_biologiya',
  [`/ege/${SUBJECTS.fizika}`]:             'new_online_school_ege_fizika',
  [`/ege/${SUBJECTS.himiya}`]:             'new_online_school_ege_himiya',
  '/free/ege/kurs-ege-2024':               'new_online_school_free_ege_kurs_ege_2024',
};
