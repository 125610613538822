export const SUBJECTS = {
  matematika: 'matematika',
  matematikaProfil: 'matematika-profil',
  russkijYazyk: 'russkij-yazyk',
  anglijskijYazyk: 'anglijskij-yazyk',
  informatika: 'informatika',
  obshchestvoznanie: 'obshchestvoznanie',
  istoriya: 'istoriya',
  fizika: 'fizika',
  himiya: 'himiya',
  biologiya: 'biologiya',
  literatura: 'literatura',
};

export const QUESTIONNARIE_NAMES = {
  common_questionnarie: 'common_questionnarie',
  ege_questionnarie: 'ege_questionnarie',
};
