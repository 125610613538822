<template>
  <div class="nuxt-error error-content">
    <component
      :is="errorPage"
      :error="error"
    />
  </div>
</template>
<script>
import error404 from '@/components/common/ErrorComponent404.vue';
import error500 from '@/components/common/ErrorComponent500.vue';

export default {
  name: 'NuxtError',
  components: {
    error404,
    error500,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404 || this.$route.name === '404') {
        return error404;
      }
      // Все остальные ошибки
      return error500;
    },
  },
};
</script>

<style scoped>
.error-content {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
</style>
